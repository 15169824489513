import { ApolloClient, InMemoryCache, HttpLink, split, ApolloLink, from } from '@apollo/client/core';
import {GraphQLWsLink}                                  from '@apollo/client/link/subscriptions';
import {getMainDefinition}                              from '@apollo/client/utilities';
import restartableWsClient                              from '@/lib/restartableWsClient';
import {removeTypenameFromVariables}                    from '@apollo/client/link/remove-typename';

function fetchWithOp(uri, options)
{
    const opName = options.operationName;

    if(opName)
        uri += `&o=${opName}`;

    return fetch(uri, options);
}

const addOpName = new ApolloLink(
    (operation, forward) =>
    {
        operation.setContext({fetchOptions:{operationName: operation.operationName}});
        return forward(operation);
    }
);

function shouldRetry()
{
    return true;
}

export function createApolloClient({ httpEndpoint, wsEndpoint, headers = {} })
{
    const httpLink  = from([addOpName, removeTypenameFromVariables(), new HttpLink({ uri: httpEndpoint, fetch: fetchWithOp, headers })]);
    const wsClient  = restartableWsClient({ url: wsEndpoint, connectionParams() { return { ...headers } }, retryAttempts: 999999, shouldRetry });
    const wsLink    = new GraphQLWsLink(wsClient);

    const splitLink = split(
        ({ query }) =>
        {
            const definition = getMainDefinition(query);
            return (
                definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription'
            );
        },
        wsLink,
        httpLink,
    );

    const apolloClient = new ApolloClient(
        {
            link: splitLink,
            cache: new InMemoryCache(
                {
                    typePolicies:
                    {
                        GroupUsersConnection: { merge: true }
                    }
                }
            )
        }
    );

    return {
        apolloClient,
        restartWs: wsClient.restart
    }
}
